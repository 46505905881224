'use client'

import { UAParser } from 'ua-parser-js'
import { getDefaultDeviceVramTitle, getDevice } from '@/lib/is-mobile'
import { PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { getTotalVramKb } from '@/lib/device'
import { DeviceType } from '@/types/configs'

export type DefaultDeviceVramTitle = Awaited<ReturnType<typeof getDefaultDeviceVramTitle>>

type Device = Awaited<ReturnType<typeof getDevice>>

type UAContextType = {
  isAndroidMobile: boolean
  isMobileApp: boolean
  defaultDeviceVramTitle: DefaultDeviceVramTitle
  device: Device
  deviceType: DeviceType
  browserName?: string
  totalVramKb?: number
}

const UAContext = createContext<UAContextType | null>(null)

export function UAProvider({
  isAndroidMobile,
  isMobileApp,
  defaultDeviceVramTitle,
  deviceType,
  device,
  children,
}: PropsWithChildren<UAContextType>) {
  const totalVramKb = getTotalVramKb(deviceType)
  const { browser } = UAParser(navigator.userAgent)
  const browserName = browser.name?.toLowerCase()

  return (
    <UAContext.Provider
      value={{
        isAndroidMobile,
        isMobileApp,
        defaultDeviceVramTitle,
        device,
        deviceType,
        totalVramKb,
        browserName,
      }}
    >
      {children}
    </UAContext.Provider>
  )
}

export const useUAContext = () => {
  const value = useContext(UAContext)

  if (value == null) {
    throw new Error('useUAContext cannot be used outside of UAProvider')
  }

  return value
}
