export const HUBS_STORE_LOCAL_KEY = '___hubs_store'

export type Credentials = {
  token: string
  email: string
  htcToken: string
  expiredAt: number
}

export type Profile = {
  id: string
  displayName: string
  avatarId: string
  avatarSnapshot: string
  avatarGlb: string
  isHalfBody: boolean
  avatarGenderKey: number
  avatarDataType: number | null
  isOver18: boolean | null
  userId: string
}

export type ViveportAuth = {
  walletAddress: string
  accountEmail: string
  accountPhoneNumber: string
}

export type GraphicSetting = {
  quality: string
  devicePixelRatio: boolean
  fpsCounter: boolean
  dynamicShadow: string
  shadowResolution: string
  canvasResolution: number
  springBone: string
  postEffect: boolean
  visibility: string
}

export type UserWorldSort = 'most_viewed' | 'most_liked' | 'create_date' | 'featured'

export type HubsStore = {
  preferences: {
    preferredMic: string
    preferredSpeakers: string
    muteMicOnEntry: boolean
    graphicSetting: GraphicSetting
    locale: string
    userWorldSort: UserWorldSort
  }
  activity: {
    hasAcceptedProfile: boolean
    hasChangedName: boolean
    lastEnteredAt: string
  }
  settings: Record<string, unknown>
  credentials: Credentials
  profile: Profile
  viveportAuth: ViveportAuth
  confirmedDiscordRooms: string[]
  confirmedBroadcastedRooms: string[]
  uploadPromotionTokens: string[]
  creatorAssignmentTokens: string[]
  embedTokens: string[]
  onLoadActions: string[]
  communityToggle: boolean
  chain: string
}

export type HubsStoreState = Partial<HubsStore>
